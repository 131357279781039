import {Link} from "gatsby";
import React from "react";

class ProcessIsEasy extends React.Component {
	render() {
		return (
			<div className="grid-container text-center padding-vertical">
				<h2 className="margin-bottom-none">The Process is Easy and Free!</h2>
				<ol className="large green centered">
					<li>Upload Your Email List</li>
					<li>Enter Your Email</li>
					<li>Get Your Free Report</li>
				</ol>
				<div>
					<Link to="/Home/Start" className="button margin-bottom">
						Get Started
					</Link>
				</div>
			</div>
		);
	}
}

export default ProcessIsEasy;
