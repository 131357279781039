import React from "react"

import Layout from "../../components/layout"
import FAQs from "../../components/faqs"
import ProcessIsEasy from "../../components/process-is-easy"

const FAQsPage = () => (
	<Layout>
		{/* FAQ Section */}
		<FAQs />
		{/* Get Started CTA Section */}
		<ProcessIsEasy />
	</Layout>
)

export default FAQsPage
